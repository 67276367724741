import React from 'react';

import { Box, Button } from '@core';
import { Text } from '@components';

const DoubleTextButton = ({
  title,
  subtitle,
  variant = 'default',
  size = 'large',
  color = 'primary',
  ...props
}) =>
  // this kind of button exists only for variants 'default' || 'outlined', color 'primary' and for 'default' and 'large' sizes only
  color === 'primary' &&
  (variant === 'default' || variant === 'outlined') &&
  (size === 'large' || size === 'default') ? (
    <Button {...props} variant={variant} color={color} size={size}>
      <Box height={size === 'large' ? 22 : 18} width="100%" position="relative">
        <Box position="absolute" top={size === 'large' ? -5 : -2} width="100%">
          <Text.Body2
            color={variant === 'default' ? 'white' : 'primary'}
            fontSize={size === 'large' ? 16 : 12}
            fontWeight={size === 'large' ? 700 : 600}
            lineHeight={1}
            pb={size === 'large' ? 8 : 4}
          >
            {title}
          </Text.Body2>
          <Text.Body4
            color={variant === 'default' ? 'white' : 'primary'}
            fontSize={size === 'large' ? '10px' : '6px'}
            fontWeight={size === 'large' ? 600 : 500}
            lineHeight={1}
          >
            {subtitle}
          </Text.Body4>
        </Box>
      </Box>
    </Button>
  ) : null;

export default DoubleTextButton;
